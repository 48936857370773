import React, { useState, useEffect, useRef, useCallback } from 'react';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faExpand, } from '@fortawesome/free-solid-svg-icons';
import Prism from 'prismjs';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-scala';
import 'prismjs/components/prism-dart';
import 'prismjs/components/prism-r';
import 'prismjs/components/prism-matlab';
import 'prismjs/components/prism-perl';
import 'prismjs/components/prism-haskell';
import 'prismjs/components/prism-lua';
import 'prismjs/themes/prism.css'; 
import 'prismjs/themes/prism-okaidia.css';
import { marked } from 'marked';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import PromptsModal from './PromptsModal';
import { BsSun, BsMoon } from 'react-icons/bs';
import { FaCog } from 'react-icons/fa';

function App() {
  const [sourceLang, setSourceLang] = useState('');
  const [targetLang, setTargetLang] = useState('');
  const [feature, setFeature] = useState('');
  const [complexity, setComplexity] = useState('simple');
  const [sourceCode, setSourceCode] = useState('Select a Source language, Target language, and Feature,\n then click "Generate" to see code examples and comparisons.');
  const [targetCode, setTargetCode] = useState('The Generated Code for your Target language will appear here after you click "Generate".');
  const [differences, setDifferences] = useState('');
  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPromptsModal, setShowPromptsModal] = useState(false);
  const [selectedPromptId, setSelectedPromptId] = useState(null);
  const [currentPromptName, setCurrentPromptName] = useState('');
  const differencesRef = useRef(null);
  const [hasGeneratedCode, setHasGeneratedCode] = useState(false);
  const [expandedCode, setExpandedCode] = useState(null);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    loadPrompts();
  }, []);

  useEffect(() => {
    if (sourceCode || targetCode) {
      Prism.highlightAll();
    }
  }, [sourceCode, targetCode]);

  useEffect(() => {
    document.body.classList.toggle('dark-theme', isDarkTheme);
    togglePrismTheme(isDarkTheme);
  }, [isDarkTheme]);

  const togglePrismTheme = (isDark) => {
    document.body.classList.toggle('prism-dark', isDark);
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const loadPrompts = async () => {
    try {
      const response = await fetch('/get_prompts');
      const data = await response.json();
      setPrompts(data);
    } catch (error) {
      console.error('Error loading prompts:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sourceLang || !feature || !targetLang || !complexity) {
      alert('Please fill in all fields');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source_lang: sourceLang,
          feature,
          target_lang: targetLang,
          complexity,
          prompt_id: currentPrompt?.id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSourceCode(data.source_code);
        setTargetCode(data.target_code);
        setDifferences(marked(data.differences));
        setHasGeneratedCode(true);
        
        // Scroll to differences section
        // if (differencesRef.current) {
        //   differencesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // }
      } else {
        alert(data.error || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSavePrompt = useCallback(async (updatedPrompt) => {
    console.log('Saving prompt:', updatedPrompt);
    const url = updatedPrompt.id ? `/update_prompt/${updatedPrompt.id}` : '/save_prompt';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPrompt),
      });

      if (response.ok) {
        alert('Prompt saved successfully');
        loadPrompts();
        setCurrentPrompt(updatedPrompt);
        setCurrentPromptName(`Current Prompt: ${updatedPrompt.name}`);
      } else {
        const data = await response.json();
        alert(data.error || 'An error occurred while saving the prompt.');
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
      alert('An error occurred while saving the prompt.');
    }
    setShowPromptsModal(false);
  }, []);

  const handleDeletePrompt = async (promptId) => {
    if (promptId === 'default') {
      alert('Cannot delete the default prompt.');
      return;
    }

    if (window.confirm('Are you sure you want to delete this prompt?')) {
      try {
        const response = await fetch(`/delete_prompt/${promptId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          alert('Prompt deleted successfully');
          loadPrompts();
          if (currentPrompt && currentPrompt.id === promptId) {
            setCurrentPrompt(null);
            setCurrentPromptName('');
          }
        } else {
          const data = await response.json();
          alert(data.error || 'An error occurred while deleting the prompt.');
        }
      } catch (error) {
        console.error('Error deleting prompt:', error);
        alert('An error occurred while deleting the prompt.');
      }
    }
  };

  const handlePromptSelection = useCallback((promptId, promptName) => {
    setSelectedPromptId(promptId);
  }, []);

  const handleUsePrompt = (prompt) => {
    setCurrentPrompt(prompt);
    setCurrentPromptName(`Current Prompt: ${prompt.name}`);
    setShowPromptsModal(false);
  };

  const languages = [
    'Python', 'JavaScript', 'Java', 'C++', 'C#', 'Ruby', 'Go', 'Swift', 'Kotlin', 'TypeScript',
    'PHP', 'Rust', 'Scala', 'Dart', 'R', 'MATLAB', 'Perl', 'Haskell', 'Lua'
  ];

  // const features = [
  //   'Variables and Data Types', 'Control Structures', 'Functions and Methods',
  //   'Object-Oriented Programming', 'Data Structures', 'File I/O', 'Exception Handling',
  //   'Concurrency and Multithreading', 'Asynchronous Programming', 'Functional Programming',
  //   'Networking', 'Database Operations', 'Regular Expressions', 'Memory Management',
  //   'Design Patterns', 'Testing and Debugging', 'Package Management', 'Web Development',
  //   'API Development'
  // ];

  const features = [
    "Basic Syntax",
    "Variable Declaration",
    "Data Types",
    "Comments",
    "String Manipulation",
    "Control Structures",
    "Loops",
    "Function Definition",
    "Array/Collection Manipulation",
    "Module Imports",
    "Object-Oriented Programming (OOP) Basics",
    "Error Handling",
    "Function Overloading",
    "Inheritance",
    "Polymorphism",
    "Data Encapsulation",
    "Anonymous Functions / Lambda Expressions",
    "List Comprehension / Array Operations",
    "Asynchronous Programming",
    "Concurrency (Threads and Processes)",
    "Closures",
    "Decorators / Annotations",
    "Operator Overloading",
    "Memory Management",
    "Generics / Templates",
    "Reflection",
    "API Calls",
    "Dependency Injection",
    "Serialization and Deserialization",
    "Advanced Object-Oriented Programming (OOP)",
    "Functional Programming Features"
  ]

  const handleExpandCode = (target) => {
    setExpandedCode(expandedCode === target ? null : target);
  };

  return (
    <div className={`App ${isDarkTheme ? 'dark-theme' : ''}`}>
      {isLoading && <div className="overlay"></div>}
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
            <a className="navbar-brand" href="/" onClick={(e) => e.preventDefault()}>SyntaxLens</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {currentPromptName && (
                <li className="nav-item">
                  <span className="nav-link current-prompt">{currentPromptName}</span>
                </li>
              )}
            </ul>
            <form className="d-flex flex-grow-1 justify-content-end" onSubmit={handleSubmit}>
              <select className="form-select me-2" value={feature} onChange={(e) => setFeature(e.target.value)} required style={{ width: 'auto' }}>
                <option value="">Feature</option>
                {features.map(f => <option key={f} value={f}>{f}</option>)}
              </select>
              <select className="form-select me-2" value={complexity} onChange={(e) => setComplexity(e.target.value)} required style={{ width: 'auto' }}>
                <option value="simple">Simple</option>
                <option value="moderate">Moderate</option>
                <option value="complex">Complex</option>
              </select>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                <FontAwesomeIcon icon={faSyncAlt} className="me-2" />
                {isLoading ? 'Generating...' : 'Generate'}
              </button>
            </form>
          </div>
        </div>
      </nav>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 pe-md-2">
            <div className={`code-section ${expandedCode === 'sourceCode' ? 'fullscreen' : ''}`}>
              <div className="select-container">
                <select className="form-select lang-select" value={sourceLang} onChange={(e) => setSourceLang(e.target.value)} required>
                  <option value="">Source Language</option>
                  {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                </select>
                <button className="expand-btn" onClick={() => handleExpandCode('sourceCode')}>
                  <FontAwesomeIcon icon={faExpand} />
                </button>
              </div>
              <div className={`code-container ${!hasGeneratedCode ? 'default-text' : ''}`}>
                <pre><code className={`line-numbers language-${sourceLang.toLowerCase()}`}>{sourceCode}</code></pre>
              </div>
            </div>
          </div>
          <div className="col-md-6 ps-md-2">
            <div className={`code-section ${expandedCode === 'targetCode' ? 'fullscreen' : ''}`}>
              <div className="select-container">
                <select className="form-select lang-select" value={targetLang} onChange={(e) => setTargetLang(e.target.value)} required>
                  <option value="">Target Language</option>
                  {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                </select>
                <button className="expand-btn" onClick={() => handleExpandCode('targetCode')}>
                  <FontAwesomeIcon icon={faExpand} />
                </button>
              </div>
              <div className={`code-container ${!hasGeneratedCode ? 'default-text' : ''}`}>
                <pre><code className={`line-numbers language-${targetLang.toLowerCase()}`}>{targetCode}</code></pre>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-12">
          <div ref={differencesRef} className="key-differences">
            <h6>Key Differences</h6>
            <div className="differences-content" dangerouslySetInnerHTML={{ __html: differences }}></div>
          </div>
        </div>
      </div>
      </div>

      <button className="theme-toggle-btn" onClick={toggleTheme}>
        {isDarkTheme ? <BsSun /> : <BsMoon />}
      </button>

      <button className="manage-prompts-btn" onClick={() => setShowPromptsModal(true)}>
        <FaCog />
      </button>

      <PromptsModal
        show={showPromptsModal}
        onHide={() => setShowPromptsModal(false)}
        currentPrompt={currentPrompt}
        onSave={handleSavePrompt}
        onDelete={handleDeletePrompt}
        prompts={prompts}
        onSelectPrompt={handlePromptSelection}
        selectedPromptId={selectedPromptId}
        onUsePrompt={handleUsePrompt}
      />
    </div>
  );
}

export default App;
