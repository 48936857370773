import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';

// Move PromptsModal outside of App component
const PromptsModal = React.memo(({ show, onHide, currentPrompt, onSave, onDelete, prompts, onSelectPrompt, selectedPromptId, onUsePrompt }) => {
  const [localPromptContent, setLocalPromptContent] = useState('');
  const [localPromptName, setLocalPromptName] = useState('');
  const [isEditingPrompt, setIsEditingPrompt] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (selectedPrompt) {
      setLocalPromptContent(selectedPrompt.content || '');
      setLocalPromptName(selectedPrompt.name || '');
    } else {
      setLocalPromptContent('');
      setLocalPromptName('');
    }
    setIsEditingPrompt(false);
  }, [selectedPrompt]);

  const handlePromptContentChange = (e) => {
    const newValue = e.target.value;
    const newPosition = e.target.selectionStart;
    
    setLocalPromptContent(newValue);
    setIsEditingPrompt(true);
    
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.setSelectionRange(newPosition, newPosition);
      }
    }, 0);
  };

  const handlePromptNameChange = (e) => {
    setLocalPromptName(e.target.value);
    setIsEditingPrompt(true);
  };

  const handleSavePrompt = useCallback(() => {
    onSave({
      id: selectedPrompt?.id,
      name: localPromptName,
      content: localPromptContent
    });
  }, [selectedPrompt?.id, localPromptName, localPromptContent, onSave]);

  const handleSelectPrompt = (promptId) => {
    const selected = prompts.find(p => p.id === promptId);
    setSelectedPrompt(selected);
    onSelectPrompt(promptId, selected?.name);
  };

  const isDefaultPrompt = selectedPrompt?.id === 'default';

  return (
    <Modal show={show} onHide={onHide} size="xl" dialogClassName="prompt-modal">
      <Modal.Header closeButton>
        <Modal.Title>{isDefaultPrompt ? 'View Default Prompt' : (selectedPrompt ? 'Edit Prompt' : 'Create New Prompt')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="prompt-modal-content">
          <div className="prompt-list-container">
            <h6>Saved Prompts</h6>
            <ListGroup className="prompt-list">
              {prompts.map(prompt => (
                <ListGroup.Item
                  key={prompt.id}
                  action
                  active={prompt.id === selectedPromptId}
                  onClick={() => handleSelectPrompt(prompt.id)}
                  className={prompt.id === 'default' ? 'default-prompt' : ''}
                >
                  {prompt.name} {prompt.id === 'default' ? '(Default)' : ''}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button variant="primary" className="mt-3" onClick={() => handleSelectPrompt(null)}>New Prompt</Button>
          </div>
          <div className="prompt-edit-container">
            <Form className="prompt-edit-form">
              <Form.Group className="prompt-name-group">
                <Form.Label>Prompt Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter prompt name"
                  value={localPromptName}
                  onChange={handlePromptNameChange}
                  readOnly={isDefaultPrompt}
                />
              </Form.Group>
              <Form.Group className="prompt-content-group">
                <Form.Label>Prompt Content</Form.Label>
                <Form.Control
                  as="textarea"
                  ref={textareaRef}
                  placeholder="Enter your prompt here..."
                  value={localPromptContent}
                  onChange={handlePromptContentChange}
                  readOnly={isDefaultPrompt}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {!isDefaultPrompt && (
          <>
            <Button variant="danger" onClick={() => onDelete(selectedPrompt?.id)} disabled={!selectedPrompt || selectedPrompt.id === 'default'}>
              Delete Prompt
            </Button>
            <Button variant="primary" onClick={handleSavePrompt} disabled={!isEditingPrompt}>
              Save Changes
            </Button>
          </>
        )}
        <Button variant="success" onClick={() => onUsePrompt(selectedPrompt)} disabled={!selectedPrompt}>
          Use Prompt
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default PromptsModal;